import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import Content, { HTMLContent } from '../components/Content';
import { GatsbyImage } from "gatsby-plugin-image";

class GalleryTemplate extends React.Component {
  constructor(props) {
    super(props);
    this.state = { selected: null };
    this.onEscape = this.onEscape.bind(this);
  }

  onEscape({ keyCode }) {
    if (keyCode === 27) {
      this.setState({ selected: null });
    }
  }

  componentDidMount() {
    document.addEventListener('keydown', this.onEscape);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.onEscape);
  }

  render() {
    const { title, content, contentComponent, images } = this.props;
    const PageContent = contentComponent || Content;

    const selectedImage =
      this.state.selected !== null ? images[this.state.selected] : null;

    return (
      <section className="section">
        {title && false ? <h2 className="title">{title}</h2> : null}
        {content && false ? (
          <PageContent className="content" content={content} />
        ) : null}
        {selectedImage ? (
          <div className="modal is-active">
            <div className="modal-background" />
            <div className="modal-content">
              <figure
                class="image"
                style={{
                  margin: '0 auto',
                  width: 'fit-content',
                  backgroundColor: 'black',
                }} // XXX: hacky
              >
                {selectedImage.image && selectedImage.image.childImageSharp ? (
                  <GatsbyImage
                    image={selectedImage.image.childImageSharp.large}
                    alt={selectedImage.title} /> // called from gatsby
                ) : (
                  <img alt={selectedImage.title} src={selectedImage.image} /> // called from netlify-cms preview
                )}
              </figure>
            </div>
            <button
              className="modal-close is-large"
              aria-label="close"
              onClick={() => this.setState({ selected: null })}
            />
          </div>
        ) : undefined}
        <div className="columns is-multiline">
          {images.map(({ title, image }, index) => (
            <div className="column is-4" key={index}>
              <div className="card">
                <div className="card-image">
                  <figure
                    className="image"
                    onClick={() => this.setState({ selected: index })}
                  >
                    {image && image.childImageSharp ? (
                      <GatsbyImage image={image.childImageSharp.thumb} alt={title} /> // called from gatsby
                    ) : (
                      <img alt={title} src={image} /> // called from netlify-cms preview
                    )}
                  </figure>
                </div>
              </div>
            </div>
          ))}
        </div>
      </section>
    );
  }
}

GalleryTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
  contentComponent: PropTypes.func,
};

const Gallery = ({ data }) => {
  const { html, frontmatter } = data.markdownRemark;

  return (
    <Layout>
      <GalleryTemplate
        contentComponent={HTMLContent}
        title={frontmatter.title}
        content={html}
        images={frontmatter.images}
      />
    </Layout>
  );
};

Gallery.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default Gallery;

export const galleryQuery = graphql`query Gallery($id: String!) {
  markdownRemark(id: {eq: $id}) {
    html
    frontmatter {
      title
      images {
        title
        image {
          id
          publicURL
          childImageSharp {
            thumb: gatsbyImageData(
              width: 600
              height: 600
              tracedSVGOptions: {color: "#433f3b", blackOnWhite: false}
              placeholder: TRACED_SVG
              layout: CONSTRAINED
            )
            large: gatsbyImageData(
              height: 640,
              tracedSVGOptions: {color: "#433f3b", blackOnWhite: false}
              placeholder: TRACED_SVG
              layout: CONSTRAINED
            )
          }
        }
      }
    }
  }
}
`;
